import { axiosClient } from "../../../utils/axios-client";

export const countryListingService = async (data) => {
  let args = {
    url: "/country",
    method: "get",
  };
  return axiosClient(args);
};

export const paymentService = async (data) => {
  let args = {
    url: "/payment",
    method: "post",
    data
  };
  return axiosClient(args);
};