import React from "react";
import styled from "styled-components";
import { FaInstagram, FaFacebookF, FaTwitter } from "react-icons/fa";
import FooterLogo from "../../../assets/images/footer-logo.png";

export default function footer() {
  return (
    <Footer>
      <div className="container">
        <div className="flex">
          <img src={FooterLogo} height="75" />
          <Flex>
            <QuickLink>
              <li>
                <a href="#">FCC PARKING</a>
              </li>
              <li>
                <a href="#">LOT LOCATIONS</a>
              </li>
              <li>
                <a href="#">CONTACT US</a>
              </li>
            </QuickLink>
            <SocialLink>
              <li>
                <a href="#">
                  <FaInstagram size={24} color="#0f253a" />
                </a>
              </li>
              <li>
                <a href="#">
                  <FaFacebookF size={24} color="#0f253a" />
                </a>
              </li>
              <li>
                <a href="#">
                  <FaTwitter size={24} color="#0f253a" />
                </a>
              </li>
            </SocialLink>
          </Flex>
        </div>
        <Copyright>Ⓒ 2022, ALL RIGHTS RESERVED.</Copyright>
      </div>
    </Footer>
  );
}

const Footer = styled.div`
  padding: 50px 0;
  background-color: #fff;
  border-top: 1px solid #2d3a4a;

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 767px) {
      flex-direction: column;


    }
  }

  @media only screen and (max-width: 991px) {
    padding: 20px 0 0;

    img {
      max-height: 40px;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;

    img {
      margin: 0 0 10px;
    }
  }
`;
const Copyright = styled.p`
  color: #909090;
  margin-bottom: 0px;

  @media only screen and (max-width: 767px) {
    text-align: center;
    margin-top: 0;
  }
`;
const QuickLink = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;

  li {
    margin: 0 20px;

    @media only screen and (max-width: 991px) {
      margin: 0 10px;
    }
    @media only screen and (max-width: 420px) {
      margin: 0 5px;
    }
    a {
      font-size: 26px;
      color: #202020;
      text-decoration: none;

      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;
const SocialLink = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin: 20px 0;
  }

  li {
    background: #ebebeb;
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      height: 50px;
      width: 50px;
    }

    a {
      svg {
        vertical-align: middle;
      }
    }
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
