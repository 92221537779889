import axios from "axios";
// import {store} from "../Store/store";

export const axiosClient = (args = {}) => {
  args.url = `${process.env.REACT_APP_API_URL}${args.url}`;
  // let auth_token = localStorage.getItem("access_token"),
    // authToken = store.getState().auth.token || auth_token,
    let headers = args.headers ? args.headers : {};
    args.headers = {
    // token: authToken,
    ...headers,
  };
  return axios(args);
};