import React from 'react'

//Imported packages.
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

//Imported files.
import Home from "../pages/home";
import Payfine from "../pages/payfine";
import Payment from "../pages/payment";

const Routing = () => {
  return (
    <>
     <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/payfine" exact element={<Payfine />} />
        <Route path="/payment" exact element={<Payment />} />
      </Routes>
      <ToastContainer />
    </Router>
    </>
  )
}

export default Routing;