import rootReducer from '../reducers/rootReducer'
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

const middlewares =
    process.env.REACT_APP_MODE === "Dev"
        ? [thunk, createLogger()]
        : [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
