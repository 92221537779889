import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

 function NoResult() {
  const searchedString = useSelector((state)=> state.searchReducer.searchedString)
  return (
    <Wrapper>
        <div className="container">
            <p>No result found for "{searchedString}", please try searching again</p>
        </div>
    </Wrapper>
  );
}

export default NoResult;
const Wrapper = styled.div`
  padding: 50px 15px;
  background: #fff;
`;
