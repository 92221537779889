import React from "react"
import { useSelector } from "react-redux";

//Imported files.
import Header from "../components/molecules/common/header";
import Search from "../components/molecules/search/search";
import HomeComponent from "../components/molecules/home";
import Footer from "../components/molecules/common/footer";
import Result from "../components/molecules/home/result";
import NoResult from "../components/molecules/home/noresult";


//Imported packages
import _ from "lodash"



function Home() {
    const searchedData = useSelector((state)=> state.searchReducer.searchedData)
  return (
    <>
      <div className="container">
        <Header />
        <Search />
       { searchedData === null ?
       <HomeComponent /> :
       !_.isEmpty(searchedData.data) ?
       <Result /> :
       <NoResult />
     }

      </div>
      <Footer />
    </>
  );
}

export default Home;
