import React from "react";

//Imported files.
import Routes from "./routers/routes"

function App() {
  return (
    <>
    <Routes />
    </>
  );
}

export default App;
