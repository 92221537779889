import React from 'react';
import ReactPaginate from "react-paginate";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import styled from "styled-components";

const Pagination = (props) => {

  const { pageCount, activePage, handlePageChange } = props

  return (
    <div className="flex-container">
      <PaginateWapper className="paginate-ctn-wapper">
        <ReactPaginate
          marginPagesDisplayed={5}
          pageRangeDisplayed={5}
          previousClassName={"page-item"}
          previousLabel={<AiOutlineLeft />}
          nextLabel={<AiOutlineRight />}
          pageCount={pageCount}
          onPageChange={(e) => handlePageChange(e)}
          containerClassName="paginate-ctn"
          subContainerClassName={"paginate-ctn"}
          activeClassName="active"
          disableInitialCallback={true}
          initialPage={0}
          forcePage={activePage - 1}
        />
      </PaginateWapper>
    </div>
  )
}

export default Pagination;

const PaginateWapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin:20px 0 5px;
  gap:3px;
  .paginate-ctn {
    display:flex;
    list-style: none;
    padding:0;
    gap:5px;
    li{
      color:#000232;
      cursor:pointer;
      font-size:14px;
      text-align:center;
      border-radius:5px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        color:#121212;
        padding:5px 5px;
        height: 20px;
        width: 20px;
        line-height: 20px;
      }
    &:hover{
      text-decoration: none;
      background:#FCAF58;
        a {
          text-decoration: none;
        }
      }
      &.active{
        background-color:#FCAF58;
        font-weight:bold
      }
    }
  }
  `