import * as constants from "../../constants";
import * as services from "../../services/index"
import { apiEffectError, apiEffectSuccess } from "../../../utils/errorHandler";


export const searchAction = (payload) =>{
        return (dispatch) => {
          return services.searchService(payload)
            .then((response) => {
              dispatch({
                type: constants.GET_SEARCHED_DATA,
                payload: response.data?.data,
              });
              // apiEffectSuccess(response?.data);
            })
            .catch((error) => {
              apiEffectError(error?.response?.data);
            });
        };
}

export const searchedStringAction = (payload) => {
    return {
      type: constants.SEARCHED_STRING,
      payload: payload,
    };
  };

export const viewPayFineDataAction = (payload) => {
    return (dispatch) => {
        return services.viewPayFineDataService(payload)
          .then((response) => {
            dispatch({
              type: constants.VIEW_PAYFINE_DATA,
              payload: response.data?.data,
            });
            // apiEffectSuccess(response?.data);
          })
          .catch((error) => {
            apiEffectError(error?.response?.data?.message);
          });
      };
  };