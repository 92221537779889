import React from "react";
import styled from "styled-components";

export default function SelectField(props) {
  return (
    <InputWrapper>
      <label>{props.label}</label>
      <select name={props.name} onChange={props.onChange}>
        <option >Select Country</option>
        {props.options.map((data) => {
          return (
            <option value={data.id}>{data.country_name}</option>
          )
        })
        }
      </select>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
margin-bottom: 20px;
label {
    font-weight: 500;
    font-size: 20px;
    background: #f5f6f6;
    display: inline-block;
    position: relative;
    margin-left: 20px;
    padding: 0 5px;
  }
  select {
    border: 1px solid #000000;
    border-radius: 6px;
    width: 100%;
    padding: 20px 20px 20px;
    box-sizing: border-box;
    font-size: 20px;
    margin-top: -15px;
    background: #f5f6f6;
    appearance: none;
    outline: none;
  }
`;
