import { axiosClient } from "../../../utils/axios-client";

export const searchService = async (data) => {
  let args = {
    url: "/search",
    method: "post",
    data,
  };
  return axiosClient(args);
};

export const viewPayFineDataService = async (data) => {
    let args = {
      url: `/ticket/${data}`,
      method: "get",
    };
    return axiosClient(args);
  };