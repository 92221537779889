import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { BiSearch } from "react-icons/bi";
import { useDispatch } from "react-redux";
import * as actions from "../../../redux/actions/index"

// Imported files.
import { searchValidationSchema } from "../../../validations/validationSchema"

function Search() {

  const dispatch = useDispatch()


  // Function for the searching.
  const handleFormSubmit = (values) => {
    let args = { value: values.search, page: 1 }
    dispatch(actions.searchedStringAction(values.search))
    dispatch(actions.searchAction(args))
  }

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    validationSchema: searchValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values)
    },
  });

  const {
    handleSubmit,
    values,
    // errors,
    // touched,
    // setSubmitting,
    // handleBlur,
    // isSubmitting,
    // dirty,
    handleChange,
    // setFieldValue,
  } = formik;
  return (
    <SearchWrapper>
      <Form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Search by Ticket ID, License Plate Number or Transaction ID"
          name="search"
          value={values.search}
          onChange={handleChange}
        />
        <BiSearch  onClick={handleSubmit} />
        {formik.touched.search && formik.errors.search ? (
          <Error>{formik.errors.search}</Error>
        ) : null}
      </Form>
    </SearchWrapper>
  );
}

export default Search;

const SearchWrapper = styled.div`
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(21px);
  -webkit-backdrop-filter: blur(21px);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position:relative

  @media only screen and (max-width: 767px) {
    padding: 10px;
  }
  @media only screen and (max-width: 1366px) {
    padding: 10px;
  }
  input {
    width: 100%;
    background: transparent;
    border: transparent;
    outline: none;
    font-size:32px;
    line-height: 42px;
    color: #2e2e38;
    @media only screen and (max-width: 1366px){
      line-height: 30px;
      font-size: 16px;
    }


    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 1.5;
    }

    @media only screen and (max-width: 420px) {
      font-size: 12px;
    }

    &::placeholder {
      color: #2e2e38;
      @media only screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }

    &:-ms-input-placeholder {
      color: #2e2e38;
      @media only screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }

    &::-ms-input-placeholder {
      color: #2e2e38;
      @media only screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
  }
`;

const Form = styled.form`
    display: flex;
    justify-content: space-between;

    width: 100%;
    @media only screen and (max-width: 1366px) {

      align-items: center;
    }
    svg{
      font-size:40px;
      @media only screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
`
const Error = styled.div`
    color: red;
    position: absolute;
    bottom: -28px;
`
