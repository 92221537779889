import React, { useEffect } from "react";

//Imported packages.
import styled, { keyframes } from "styled-components";
import { BiCreditCard, BiArrowBack } from "react-icons/bi";
import Bg from "../../../assets/images/payfine_bg.png";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Imported Files.
import InputField from "../../atoms/input/InputField";
import SelectField from "../../atoms/input/SelectField";
import CardOne from "../../../assets/images/card1.svg";
import CardTwo from "../../../assets/images/card2.svg";
import CardThree from "../../../assets/images/card3.svg";
import CardFour from "../../../assets/images/card4.svg";
import * as actions from "../../../redux/actions/index";
import { paymentValidationSchema } from "../../../validations/validationSchema"

function PaymentComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const viewPayFineData = useSelector((state) => state.searchReducer.viewPayFineData);
  const countryListingData = useSelector((state) => state.paymentReducer.countryListingData);
  const searchReducer = useSelector(state => state.searchReducer)



  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#424770",
        fontSize: '18px',
        lineHeight: '32px',
        '::placeholder': {
          color: "#fffff",
        },
      },
      invalid: {
        color: "#000000",
        iconColor: "#000000",
      },
    },
    hidePostalCode: true,
  };

  useEffect(() => {
    dispatch(actions.countryListingAction())
    redirectToHome();
  }, []);


  // If user didn't get the data, this function redirect the user on the home page.
  const redirectToHome = () => {
    if (_.isEmpty(viewPayFineData)) {
      return navigate("/")
    }
  }

  const paymentSubmit = async (values, setSubmitting, isSubmitting) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    setSubmitting(true);

    if (!stripe || !elements) {
      setSubmitting(false);

      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      //`Elements` instance that was used to create the Payment Element
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (payload.error) {
      // Show error to your customer (for example, payment details incomplete)
      setSubmitting(false);
      toast.error(payload.error.message);
    } else {

      const args = {
        name: values.cardHolderName,
        email: values.cardHolderEmail,
        country_id: values.country,
        postal_code: values.zipCode,
        payment_method: payload.paymentMethod?.id,
        ticket_id: viewPayFineData.ticket_id,
        secure: 0
      }
      // 2D Secure payment.
      dispatch(actions.paymentAction(args)).then((res) => {
        if (!res.error) {
          let args = { value: searchReducer.searchedString, page: searchReducer.current_page }

          setSubmitting(false)
          dispatch(actions.emptyGetClientSecretId([]))
          dispatch(actions.searchAction(args))
          navigate("/")
        } else {
          //3D Secure payment with the client secret id.
          res && res.data && res.data.client_secret &&
            stripe.confirmCardPayment(res.data.client_secret).then((result) => {
              setSubmitting(true)

              if (result?.error) {
                toast.error("Payment Failed!");
                setSubmitting(false)
              } else if (result?.paymentIntent?.status === "succeeded") {
                const updateValues = {
                  name: values.cardHolderName,
                  email: values.cardHolderEmail,
                  country_id: values.country,
                  postal_code: values.zipCode,
                  payment_method: result?.paymentIntent?.id,
                  ticket_id: viewPayFineData.ticket_id,
                  secure: 1
                }
                dispatch(actions.paymentAction(updateValues)).then((res) => {
                  setSubmitting(false);
                  if (!res.error) {
                    let args = { value: searchReducer.searchedString, page: searchReducer.current_page }

                    setSubmitting(false);
                    dispatch(actions.emptyGetClientSecretId([]))
                    dispatch(actions.searchAction(args))
                    navigate("/")
                  }
                })

              }
            })
        }
      })


    }

  };

  const formik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardHolderEmail: "",
      country: "",
      zipCode: ""
    },
    enableReinitialize: true,
    validationSchema: paymentValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      paymentSubmit(values, setSubmitting, isSubmitting)

    }
  });
  const { handleSubmit, handleChange, isSubmitting, values, touched, errors } = formik

  return (

    <Wrapper>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <Flex className="space-between">
            <FlexOne>
              <TicketID>
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <BiArrowBack />
                </button>
                Ticket ID - {viewPayFineData.ticket_id}
              </TicketID>
              <Payment>
                <Cards>
                  <img src={CardOne} alt="" />
                  <img src={CardTwo} alt="" />
                  <img src={CardThree} alt="" />
                  <img src={CardFour} alt="" />
                </Cards>
                <Relative>
                  <InputField
                    name="cardHolderEmail"
                    placeholder="Enter the Email Address"
                    label="Email Address"
                    type="text"
                    value={values.cardHolderEmail}
                    onChange={handleChange}
                  />
                  {touched.cardHolderEmail && errors.cardHolderEmail ? (
                    <Error>{errors.cardHolderEmail}</Error>
                  ) : null}
                </Relative>
                <Relative>
                  <InputField
                    name="cardHolderName"
                    placeholder="Enter the Name"
                    label="Cardholder Name"
                    type="text"
                    value={values.cardHolderName}
                    onChange={handleChange}
                  />
                  {touched.cardHolderName && errors.cardHolderName ? (
                    <Error>{errors.cardHolderName}</Error>
                  ) : null}
                </Relative>
                {/* <InputField placeholder="XXXXXXXXXXXXXXX" label="Card Details" /> */}
                <PaymentMethod>
                  <label>Card info</label>
                  <CardPayment>

                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </CardPayment>
                </PaymentMethod>
                <Grid>
                  <Relative>
                    <SelectField name="country" label="Country" options={countryListingData} value={values.country} onChange={handleChange} />
                    {touched.country && errors.country ? (
                      <Error className="error-code">{errors.country}</Error>
                    ) : null}
                  </Relative>
                  <Relative>
                    <InputField name="zipCode" type="text" placeholder="Zip Code" label="Zip Code" onChange={handleChange} />
                    {touched.zipCode && errors.zipCode ? (
                      <Error className="error-code">{errors.zipCode}</Error>
                    ) : null}
                  </Relative>
                </Grid>
              </Payment>
            </FlexOne>
            <AmountDue>
              <span>
                Pay
                <br /> Park CINCY
              </span>
              ${viewPayFineData.amount}
              <PayFineBtn type="submit" disabled={isSubmitting}>
                {isSubmitting ? <Circle /> : <BiCreditCard size={40} />}
                {!isSubmitting ? "Pay fine" : null}
              </PayFineBtn>
            </AmountDue>
          </Flex>
        </form>
      </div>
    </Wrapper>
  );
};

export default PaymentComponent;

const Wrapper = styled.div`
  padding: 50px 15px;
  background: #fff;
  @media only screen and (max-width: 1366px){
    padding: 28px 15px;
  }
`;
const TicketID = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 0;
  color: #0f3059;
  font-weight: 600;
  font-size: 45px;
  @media only screen and (max-width: 1366px){
    font-size: 28px;
  }
  button {
    background: #2d3a4a;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 20px;
    color: #fff;
    border: 0;
    font-size: 40px;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 35px;
    cursor: pointer;
    @media only screen and (max-width: 1366px){
      width: 50px;
      height: 50px;
      border-radius: 10px;
      font-size: 25px;
      margin-right: 15px;
      }
  }
`;
const Flex = styled.div`
  display: flex;
  margin-top: 30px;
  @media only screen and (max-width: 1366px){
    margin-top: 10px;
   }
   @media only screen and (max-width: 991px){
     flex-direction: column;
    }

  &.space-between {
    justify-content: space-between;
  }
`;
const FlexOne = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const AmountDue = styled.div`
  width: 270px;
  margin-left: 63px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 90px;
  color: #fff;
  background: linear-gradient(
    179deg,
    rgba(15, 37, 58, 1) 0%,
    rgba(16, 38, 59, 1) 0%,
    rgba(46, 60, 93, 1) 73%,
    rgba(57, 68, 106, 1) 100%
  );
  flex-direction: column;
  line-height: 1;
  border-radius: 20px;
  margin-right: 0px;
  padding: 20px;
  position: relative;
  @media only screen and (max-width: 1366px){
    margin-left: 40px
  }
  @media only screen and (max-width: 991px){
    width:calc(100% - 40px);
    margin:0;
    margin-top: 15px;
    }
  span {
    font-size: 32px;
    margin: 20px 0;
    line-height: 1.5;
  }
  p {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin: 10px 0 0;
  }
`;
const PayFineBtn = styled.button`
  background: #539b77;
  color: #ffffff;
  font-size: 31px;
  border-radius: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  border: 0;
  height: 78px;
  line-height: 78px;
  width: 100%;
  justify-content: center;
  font-weight: normal;
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  cursor: pointer;
  @media only screen and (max-width: 991px){
    position: relative;
    bottom:0;
    left:0;
    margin-top: 10px;
    font-size: 26px;
    height: 65px;
  }
  svg {
    margin-right: 15px;
  }
`;
const Payment = styled.div`
  background: url(${Bg});
  background-repeat: no-repeat;
  margin-top: 60px;
  padding: 70px;
  position: relative;
  background-size: 100% 100%;
  @media only screen and (max-width: 1366px){
    margin-top: 20px;
    padding: 40px;
    }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const Cards = styled.div`
  background: #dfdfe0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  @media only screen and (max-width: 767px){
  display: inherit;

 }
  img {
    margin: 0 10px;
    height: 45px;

  }
`;

const Error = styled.div`
    color: red;
    position: absolute;
    top: calc( 100% + 2px);
    bottom:auto;
    font-size: 12px;
`

const spin = keyframes`
0% { transform: rotate(0deg) }
100% {transform: rotate(360deg) }
`;
const Circle = styled.div`
  border: 3px solid #b0d5ff;
  border-radius: 50%;
  border-top: 3px solid #ffffff;
  margin: auto;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s linear infinite;

`;

const PaymentMethod = styled.div`
margin-bottom: 20px;
label{
  font-weight: 500;
  font-size: 20px;
  background: #f5f6f6;
  display: inline-block;
  position: relative;
  margin-left: 20px;
  padding: 0 5px;
 }
 `
const CardPayment = styled.div`
border: 1px solid #000000;
border-radius: 6px;
width: 100%;
padding: 15px 20px;
box-sizing: border-box;
font-size: 20px;
margin-top: -15px;
background: #f5f6f6;
outline: none;
`
const Relative = styled.div`
position: relative;
.error-code{
  bottom: auto;
  top: calc(100% - 19px);
}
`