import Header from "../components/molecules/common/header";
import Footer from "../components/molecules/common/footer";
import PayfineComponent from "../components/molecules/payfine";
function Home() {
  return (
    <>
      <div className="container">
      <Header />
      </div>
      <PayfineComponent />
      <Footer />
    </>
  );
}

export default Home;
