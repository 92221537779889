import * as constants from "../../constants";
import * as services from "../../services/index"
import { apiEffectError, apiEffectSuccess } from "../../../utils/errorHandler";


export const countryListingAction = (payload) =>{
        return (dispatch) => {
          return services.countryListingService(payload)
            .then((response) => {
              dispatch({
                type: constants.GET_COUNRTY_LISTING,
                payload: response.data?.data,
              });
            //   apiEffectSuccess(response?.data);
            })
            .catch((error) => {
              apiEffectError(error?.response?.data);
            });
        };
}

export const paymentAction =  (payload) =>{
    let res;
    return async (dispatch) => {
      await services.paymentService(payload)
        .then((response) => {
          dispatch({
            type: constants.PAYMENT_SUCCESS,
            payload: response.data,
          });
          apiEffectSuccess(response?.data);
           res = response.data
        })
        .catch((error) => {
          apiEffectError(error?.response?.data);
          res = error;
        });
        return res
    };
}

export const emptyGetClientSecretId = (payload) => {
    return {
        type: constants.EMPTY_GET_CLIENT_SECRET_ID,
        payload: payload
    }

}

