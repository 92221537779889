import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Header from "../components/molecules/common/header";
import Footer from "../components/molecules/common/footer";
import PaymentComponent from "../components/molecules/payment";

function Payment() {
    const stripePromise = loadStripe(process.env.STRIPE_KEY || 
        "pk_test_rKR74NieIHm9NzB7u0zx7wcj");

  return (
    <>
        <div className="container">
            <Header />
        </div>
        <Elements stripe={stripePromise} >
            <PaymentComponent />
        </Elements>
      
        <Footer />
    </>
  );
}

export default Payment;
