import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import Search from "../../../assets/search.json";
import Review from "../../../assets/review.json";
import Pay from "../../../assets/pay.json";

export default function HomeComponent() {
  return (
    <HomeWrapper>
      <Header>
        Clear your fine in just <strong>THREE</strong> hassle free steps
      </Header>
      <Grid>
        <li>
          <Flex>
            <Number>1</Number>
            <Heading>SEARCH</Heading>
          </Flex>
          <p>
            Search for your ticket by entering the Ticket ID (eg. PCT123) or
            your Car Registration Number (eg. ABC1234) in the search field above
          </p>
          <Lotify>
            <Lottie
              className="right"
              animationData={Search}
              loop={true}
              speed={2}
            />
          </Lotify>
        </li>
        <li>
          <Flex>
            <Number>2</Number>
            <Heading>REVIEW</Heading>
          </Flex>
          <p>
            Select the Ticket (if there are multiple) and Verify your Ticket
            details.
          </p>
          <Lotify>
            <Lottie animationData={Review} loop={true} />
          </Lotify>
        </li>
        <li>
          <Flex>
            <Number>3</Number>
            <Heading>PAY</Heading>
          </Flex>
          <p>Enter your card details and pay securely</p>
          <Lotify>
            <Lottie animationData={Pay} loop={true} />
          </Lotify>
        </li>
      </Grid>
    </HomeWrapper>
  );
}

const HomeWrapper = styled.div`
  backdrop-filter: blur(21px);
  -webkit-backdrop-filter: blur(21px);
  background: rgba(255, 255, 255, 0.75);
  margin: 30px 0;
`;
const Header = styled.div`
  background: #fff;
  font-size: 32px;
  text-align: center;
  padding: 15px;

  strong {
    text-decoration: underline;
  }
  @media only screen and (max-width: 1366px) {
   font-size:20px;

  }


`;
const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
  @media only screen and (max-width: 1366px) {
   gap:20px;
   }
   @media only screen and (max-width: 767px) {
    grid-template-columns: auto;
    }


  li {
    padding: 20px;
    border-right: 1px solid #0f253a;
    @media only screen and (max-width: 1366px) {
      padding:10px;
    }
    @media only screen and (max-width: 767px) {
      border-bottom: 1px solid #0f253a;
      border-right:0;
      }
    @media only screen and (max-width: 991px){
      padding-left: 10px;
    }

    &:last-child {
      border-right: 0;
      @media only screen and (max-width: 767px) {
        border-bottom: 0;

        }
    }

    &:first-child {
      padding-left: 40px;
      @media only screen and (max-width: 1366px) {
       padding-left:25px;
      }
      @media only screen and (max-width: 767px) {
        padding-left:10px;
       }
    }

    p {
      min-height: 120px;
    }
  }
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const Number = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0f253a;
  color: #fff;
  font-size: 56px;
  margin-right: 15px;
  @media only screen and (max-width: 1366px) {
    font-size:30px;
    height: 100px;
    width: 50px;
    height:50px;
    border-radius: 10px;
   }
`;
const Heading = styled.h2`
  font-size: 45px;
  @media only screen and (max-width: 1366px) {
    font-size:28px;

   }
`;
const Lotify = styled.div`
  height: 70px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
  margin-bottom: 50px;
`;
