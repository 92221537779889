import * as constants from "../../constants"

  
  let initialState = {
    countryListingData: [],
    getSecretKey: []
    
  };
  
  export default function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_COUNRTY_LISTING:
            return {
                ...state,
                countryListingData: action.payload,
            }; 

        case constants.PAYMENT_SUCCESS:
            return {
                ...state,
                getSecretKey: action.payload
            }  
            
        case constants.EMPTY_GET_CLIENT_SECRET_ID:
            return {
                ...state,
                getSecretKey: action.payload
            }                

        default:
            return {
                ...state,
            };
    }
  }
  