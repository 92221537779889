import * as Yup from "yup";

export const searchValidationSchema = Yup.object({
  search: Yup.string()
  .trim()
  .required("Search field is required."),
});

export const paymentValidationSchema = Yup.object({
  cardHolderEmail: Yup.string()
  .trim()
  .email("Invalid email.")
  .required("Email field is required."),
  cardHolderName: Yup.string()
  .trim()
  .required("Cardholder name is required."),
  country: Yup.string()
  .trim() 
  .required("Country field is required."),
  zipCode:  Yup.string()
  .trim()
  // .matches("/[^A-Za-z0-9]+/","Special characters are not allowed.")
  .required("Zip Code is required.")
});