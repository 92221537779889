import { toast } from 'react-toastify'

const options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const apiEffectError = (props) => {
    return toast.error(props?.message || "Something went wrong.", {...options})
}

export const apiEffectSuccess = (props) => {
    return toast.success(props?.message || "Success!", {...options})
}