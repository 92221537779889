import styled from "styled-components";
import Logo from "../../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
function Header() {
  return (
    <HeaderWrapper>
      <NavLink to="/">
        <img src={Logo} alt="" />
      </NavLink>
      <ul>
        <li>
          <NavLink to="#" activeClassName="active">
            PAY FINE
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeClassName="active">
            CONTACT US
          </NavLink>
        </li>
      </ul>
    </HeaderWrapper>
  );
}

export default Header;

const HeaderWrapper = styled.header`
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    padding: 15px 0;

    img {
      max-width: 100px;
    }
  }


  @media only screen and (max-width: 1366px) {
    padding: 15px 0;

    img {
      max-width: 100px;
    }
  }

  ul {
    list-style: none;
    display: inline-flex;
    margin: 0;
    padding: 0;

    li {
      margin-left: 30px;

      &:first-child {
        margin: 0;
      }

      @media only screen and (max-width: 767px) {
        margin-left: 15px;
      }

      a {
        color: #ffffff;
        text-transform: uppercase;
        font-size: 32px;
        text-decoration: none;
        font-weight: 500;
        @media only screen and (max-width: 1366px) {
          font-size: 16px;
        }

        &:hover,
        &.active {
          border-bottom: 2px solid #fff;
        }

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
`;
