import React from "react";

// Imported packages.
import styled from "styled-components";
import { AiOutlineEye } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";


// Imported files.
import Pagination from "../../atoms/input/pagination/pagination";
import * as actions from "../../../redux/actions/index"



function Result() {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const searchedData = useSelector(state => state.searchReducer.searchedData)
  const searchedString = useSelector(state => state.searchReducer.searchedString)

  const handlePageChange = (e) => {
    let args = { value: searchedString, page: e.selected + 1 }
    dispatch(actions.searchAction(args))
  }

  // Redirect to the Pay Fine page with selected data.
  const handleViewPayFine = (e) => {
    dispatch(actions.viewPayFineDataAction(e)).then((_res) => {
      navigate('/payfine')
    })
  }

  // Serial Number according to pagination.
  const serialNum = (index) => {
    return (
      searchedData.per_page *
      (searchedData.current_page - 1) +
      (index + 1)
    );

  }

  // Date format
  const dateFormat = (value) => {
    return moment(value).format("DD/MM/YYYY")
  }

  // Time format
  const timeFormat = (value) => {
    return moment(value, "HH:mm").format("hh:mm A");
  }

  //Function for paid or amount view.
  const handlePaidOrAmountView = (value) => {
    return value == 0 ? true : false
  }

  return (
    <Wrapper>
      <div className="container">
        <p>{searchedData.total} tickets found for “{searchedString}”</p>
        <TableContent>
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Ticket ID</th>
                <th>License Plate Number</th>
                <th>Amount Due</th>
                <th>Date and time of Ticket creation</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>

              {searchedData.data.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{serialNum(index)}</td>
                    <td>{data.ticket_id}</td>
                    <td>{data.vehicle_registration_number}</td>
                    <td>{handlePaidOrAmountView(data.status) ? <Amount>${data.amount}</Amount> : <AmountPaid>PAID</AmountPaid>}</td>
                    <td>{dateFormat(data.date)} {timeFormat(data.time)}</td>
                    <td>
                      <Action>
                        <AiOutlineEye  color="#fff" cursor="pointer" onClick={() => handleViewPayFine(data.ticket_id)} />
                      </Action>
                    </td>
                  </tr>
                )
              })
              }
            </tbody>

          </table>
        </TableContent>
        <Pagination pageCount={searchedData.last_page} activePage={searchedData.current_page} handlePageChange={handlePageChange} />
      </div>
    </Wrapper>
  );
}

export default Result;

const Wrapper = styled.div`
  padding: 50px 15px;
  background: #fff;
  margin-top: 50px;
  @media only screen and (max-width: 767px){
    padding: 20px 15px;
    margin-top: 28px;
  }

  p {
    font-size: 26px;
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (max-width: 767px){
    margin:0;
    font-size:20px;
    }
  }

  table {
    width: 100%;
    border: 1px solid #ddd;
    margin-top: 20px;

    tr {
      th {
        text-align: left;
        font-size: 20px;
        padding: 15px;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
           padding: 10px;
        }
      }
      td {
        font-size: 20px;
        border-top: 1px solid #ddd;
        padding: 15px;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
           padding: 10px;
        }
      }
    }
  }
`;

const Amount = styled.div`
  width: 100px;
  border-radius: 14px;
  background: #2d3a4a;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  padding: 5px;
  box-sizing: border-box;
  @media only screen and (max-width: 767px){
    width: 80px;
    border-radius: 10px;
    font-size: 16px;
  }
`;
const AmountPaid = styled.div`
  width: 100px;
  border-radius: 14px;
  background: #539B77;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  padding: 5px;
  box-sizing: border-box;
`;

const Action = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0f3059;
  svg{
    font-size:30px;
    @media only screen and (max-width: 767px){
     font-size:14px;
    }
  }
  @media only screen and (max-width: 767px){
    height: 30px;
    width: 30px;
  }
`;
const TableContent = styled.div`
 overflow-x:auto;
 width:100%;

`