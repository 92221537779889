import * as constants from "../../constants"
  
  let initialState = {
    searchedString: "",
    viewPayFineData: [],
    searchedData: null,
    current_page: 1,
  };
  
  export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case constants.SEARCHED_STRING:
            return {
                ...state,
                searchedString: action.payload,
            };
        case constants.GET_SEARCHED_DATA:
                return {
                    ...state,
                    searchedData: action.payload,
                    current_page: action.payload.current_page || 1
                };
        case constants.VIEW_PAYFINE_DATA:
             return {
                ...state,
                viewPayFineData: action.payload,
            }; 

        default:
            return {
                ...state,
            };
    }
  }
  