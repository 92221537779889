import React, { useState, useEffect } from "react";
// Imported Packages.
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import _ from "lodash"
import moment from "moment";
import styled from "styled-components";
import { BiImage, BiCreditCard, BiArrowBack } from "react-icons/bi";
import { saveAs } from "file-saver";
//Imported Images.
import Bg from "../../../assets/images/payfine_bg.png";
// import Car from "../../../assets/images/car.png";


function PayfineComponent() {

  const navigate = useNavigate();
  const viewPayFineData = useSelector((state) => state.searchReducer.viewPayFineData);

  // Component State values.
  const [popup, setPopup] = useState(false);


  useEffect(() => {
    document.body.classList.add("payfine");
    redirectToHome();
  }, []);

  const popupToggle = () => {
    setPopup(!popup);
    document.body.classList.toggle("modal-open");
  };

  // If user didn't get the data, this function redirect the user on the home page.
  const redirectToHome = () => {
    if (_.isEmpty(viewPayFineData)) {
      return navigate("/")
    }
  }

  // Date format
  const dateFormat = (value) => {
    return moment(value).format("DD/MM/YYYY")
  }

  // Time format
  const timeFormat = (value) => {
    return moment(value, "HH:mm").format("hh:mm A");
  }

  //Redirect to payment page.
  const redirectToPaymentPage = () => {
    navigate('/payment')
  }

  // Function for paid due or paid view.
  const handlePaidDueOrPaidView = (value) => {
    return value == 0 ? true : false
  }



  return (
    <>
      <Wrapper>
        <div className="container">
          <Flex className="space-between">
            <FlexOne>
              <TicketID>
                <button
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <BiArrowBack />
                </button>
                Ticket ID - {viewPayFineData.ticket_id}
              </TicketID>
              <PayFine>
                <PayFineRow>
                  <PayFineElements>
                    <ElementLabel>Date</ElementLabel>
                    <ElementData>{dateFormat(viewPayFineData.date)}</ElementData>

                  </PayFineElements>
                  <PayFineElements>
                    <ElementLabel>Time</ElementLabel>
                    <ElementData>{timeFormat(viewPayFineData.time)}</ElementData>

                  </PayFineElements>
                  <PayFineElements>
                    <ElementLabel>License Plate Number</ElementLabel>
                    <ElementData>{viewPayFineData.vehicle_registration_number}</ElementData>

                  </PayFineElements>
                </PayFineRow>
                <PayFineBreak />
                <PayFineRow>
                  <PayFineElements>
                    <ElementLabel>Vehicle Color</ElementLabel>
                    <ElementData>
                      <span
                        className="color"
                        style={{ backgroundColor: `${viewPayFineData.vehicle_colour}` }}

                      ></span>
                      {viewPayFineData.vehicle_colour}
                    </ElementData>
                  </PayFineElements>
                  <PayFineElements>
                    <ElementLabel>Vehicle Model</ElementLabel>
                    <ElementData>{viewPayFineData.vehicle_model}</ElementData>

                  </PayFineElements>
                  <PayFineElements>
                    <ElementLabel>Vehicle Image</ElementLabel>
                    <ElementData>
                      <ViewImage onClick={popupToggle}>
                        View car image
                      </ViewImage>
                    </ElementData>
                  </PayFineElements>
                </PayFineRow>
                <PayFineBreak />
                {viewPayFineData.transaction_id && (
                  <>
                    <PayFineRow>
                      <PayFineElements>
                        <ElementLabel>Transaction ID</ElementLabel>
                        <ElementData>
                          {viewPayFineData.transaction_id}
                        </ElementData>
                      </PayFineElements>
                      <PayFineElements>
                        <ElementLabel></ElementLabel>
                        <ElementData>
                        </ElementData>
                      </PayFineElements>
                      <PayFineElements>
                        <ElementLabel>Transaction Status</ElementLabel>
                        <ElementData>{viewPayFineData.transaction_status}</ElementData>
                      </PayFineElements>
                    </PayFineRow>
                    <PayFineBreak />
                  </>
                )}
                <PayFinecontent>
                  In case of any issues regarding the ticket fine, please
                  contact us at{" "}
                  <a href="tel:+ 44 2312312341">+ 44 2312312341</a>
                </PayFinecontent>
              </PayFine>
            </FlexOne>
            {handlePaidDueOrPaidView(viewPayFineData.status) ? <AmountDue>
              <span>DUE</span> ${viewPayFineData.amount}
              <p>Parked at the wrong spot</p>
              <PayFineBtn onClick={redirectToPaymentPage}>
                <BiCreditCard size={40} />
                Pay fine
              </PayFineBtn>
            </AmountDue>
              :
              <AmountPaid>
                <span>Paid ${viewPayFineData.amount}</span>
              </AmountPaid>

            }
          </Flex>
        </div>
      </Wrapper>

      <Popup className={`${popup ? "active" : ""}`}>
        <PopupMain>
          <PopupHeader>
            <button onClick={popupToggle}>
              <BiArrowBack />
            </button>
            <button
              className="download"
              onClick={() => {
                saveAs(viewPayFineData.full_image);
              }}
            >
              <BiImage />
              Download image
            </button>
          </PopupHeader>
          <PopupBody>
            <Img src={viewPayFineData.full_image} alt="" />

          </PopupBody>
        </PopupMain>
      </Popup>
      <PopupOverlay
        className={`${popup ? "active" : ""}`}
        onClick={popupToggle}
      />
    </>
  );
}

export default PayfineComponent;

const Wrapper = styled.div`
  padding: 50px 15px;
  background: #fff;
  @media only screen and (max-width: 1366px){
    padding: 28px 15px;
  }
`;
const TicketID = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 0;
  color: #0f3059;
  font-weight: 600;
  font-size: 45px;
  @media only screen and (max-width: 1366px){
    font-size: 28px;
  }
  button {
    background: #2d3a4a;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    border-radius: 20px;
    color: #fff;
    border: 0;
    font-size: 40px;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 35px;
    cursor: pointer;
    @media only screen and (max-width: 1366px){
    width: 50px;
    height: 50px;
    border-radius: 20px;
    font-size: 25px;
    margin-right: 15px;
    }
  }
`;
const Flex = styled.div`
  display: flex;
  margin-top: 30px;
  @media only screen and (max-width: 1366px){
   margin-top: 10px;
  }
  @media only screen and (max-width: 991px){
    flex-direction: column;
   }

  &.space-between {
    justify-content: space-between;
  }
`;
const FlexOne = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const AmountDue = styled.div`
  width: 270px;
  margin-left: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  color: #fff;
  background: linear-gradient(
    179deg,
    rgba(15, 37, 58, 1) 0%,
    rgba(16, 38, 59, 1) 0%,
    rgba(46, 60, 93, 1) 73%,
    rgba(57, 68, 106, 1) 100%
  );
  flex-direction: column;
  line-height: 1;
  border-radius: 20px;
  margin-right: 0px;
  padding: 20px;
  position: relative;
  @media only screen and (max-width: 1366px){
    margin-left: 40px
  }
  @media only screen and (max-width: 991px){
    width:calc(100% - 40px);
    margin:0;
    margin-top: 15px;
    }
  span {
    font-size: 32px;

  }
  p {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin: 10px 0 0;
  }
`;
const AmountPaid = styled.div`
  width: 270px;
  margin-left: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  color: #fff;
  background:transparent linear-gradient(180deg, rgba(83, 155, 119, 1) 0%, rgba(42, 78, 60, 1) 100%) 0% 0% no-repeat padding-box;
  flex-direction: column;
  line-height: 1;
  border-radius: 20px;
  margin-right: 0px;
  padding: 20px;
  position: relative;

  span {
    font-size: 32px;
  }
  p {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin: 10px 0 0;
  }
`;
const PayFineBtn = styled.button`
  background: #539b77;
  color: #ffffff;
  font-size: 31px;
  border-radius: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  border: 0;
  height: 78px;
  line-height: 78px;
  width: 100%;
  justify-content: center;
  font-weight: normal;
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  cursor: pointer;
  @media only screen and (max-width: 991px){
    position: relative;
    bottom:0;
    left:0;
    margin-top: 10px;
    font-size: 26px;
    height: 65px;
  }
  svg {
    margin-right: 15px;
  }
`;
const PayFine = styled.div`
  background: url(${Bg});
  background-repeat: no-repeat;
  margin-top: 60px;
  padding: 70px;
  position: relative;
  background-size: 100% 100%;
  @media only screen and (max-width: 1366px){
   margin-top: 20px;
   padding: 50px;
   }
   @media only screen and (max-width: 767px){
    padding:30px;
    }
`;
const PayFineRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 767px){
    flex-direction: column;
  }
`;
const PayFineBreak = styled.hr`
  margin: 50px 0;
  border: 1px solid #e2e4e6;
  @media only screen and (max-width: 1366px){
    margin: 42px 0;
  }
`;
const PayFineElements = styled.div`
  width: 33.33%;
  @media only screen and (max-width: 767px){
    width:100%;
  }
`;
const ElementLabel = styled.label`
  font-size: 28px;
  color: #000;
  line-height: 36px;
  font-weight: 600;
  @media only screen and (max-width: 1366px){
    font-size:22px;
  }
`;
const ElementData = styled.p`
  margin: 0;
  padding-top: 25px;
  font-size: 25px;
  line-height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  @media only screen and (max-width: 1366px){
    font-size:20px;
    padding-top:15px;
  }
  @media only screen and (max-width: 767px){
    padding:0;
    padding-bottom:20px;
  }
  .color {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: #000;
    display: inline-flex;
    margin-right: 20px;
    @media only screen and (max-width: 1366px){
      width: 28px;
    height: 28px;
    margin-right: 15px;
    }
  }
`;
const ViewImage = styled.button`
  font-size: 20px;
  color: #fff;
  height: 57px;
  line-height: 57px;
  text-align: center;
  background: rgba(45, 58, 74, 0.81);
  border-radius: 11px;
  border: 0;
  cursor: pointer;
  padding: 0 26px;
  @media only screen and (max-width: 1366px){
    height: 45px;
    line-height: 47px;
  }
`;
const PayFinecontent = styled.p`
  font-size: 22px;
  margin-bottom: 0;
  @media only screen and (max-width: 1366px){
    font-size: 20px;
  }
  a {
    color: #2e2e38;
    text-decoration: none;
    font-weight: normal;
  }
`;
const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #0000;
  z-index: 111;
  margin: auto;
  display: none;
  &.active {
    display: flex;
  }
`;
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #2e2e38e5;
  backdrop-filter: blur(45px);
  z-index: 11;
  bottom: 0;
  right: 0;
  margin: auto;
  display: none;
  &.active {
    display: flex;
  }
`;
const PopupMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  width: 80%;
  margin: auto;
`;
const PopupHeader = styled.div`
  display: flex;
  top: 10px;
  position: absolute;
  button {
    background: #fff;
    width: 80px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 20px;
    color: #2d3a4a;
    border: 0;
    font-size: 40px;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 35px;
    cursor: pointer;
    @media only screen and (max-width: 767px){
      height: 50px;
      border-radius:20px;
      line-height: 47px;
    }
    &.download {
      font-size: 27px;
      padding: 0 30px;
      width: auto;
      @media only screen and (max-width: 767px){
        font-size: 15px;
         padding: 0 15px;

       }
    }
  }
`;
const PopupBody = styled.div`
  margin-top: 34px;
  box-shadow: 0px 3px 7px #0f30590d;
  border-radius: 10px;

`;
const Img = styled.img`
  border-radius: 10px;
  max-width: 100%;
 width:100%;
`;
